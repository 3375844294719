import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout, SEO } from '../components/common';
import {
  Container,
  PageWrapper,
  Details,
  ProjectCard,
  ResponsiveDesktopContainer,
  TagContainer,
  Tag,
  OneLiner,
  AlignRight,
} from '../styles';
import { Header } from '../components/theme';

const Projects = function () {
  const data = useStaticQuery(graphql`
    query Images {
      breathingAnalysisImage: file(relativePath: { eq: "breathing-analysis-hero.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      buildingAnalyticsImage: file(relativePath: { eq: "brendan-iribe-center.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      healthcareImage: file(relativePath: { eq: "reading.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      stopCovidImage: file(relativePath: { eq: "medical-examination-covid.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO />
      <Header />
      <PageWrapper as={Container}>
        <Details>
          <h1>Projects</h1>
          <ResponsiveDesktopContainer>
            <ProjectCard onClick={() => navigate('/breathing-analysis')}>
              <GatsbyImage
                image={data.breathingAnalysisImage.childImageSharp.gatsbyImageData}
                alt="breathing analysis image"
              />
              <h2>Breathing Analysis</h2>
              <TagContainer>
                <Tag> signals processing </Tag>
                <Tag> machine learning </Tag>
                <Tag> sensors </Tag>
                <Tag> physiology </Tag>
              </TagContainer>
              <OneLiner>
                Relax and take a deep breath! Let’s discover what our breathing can tell us about ourselves.
              </OneLiner>
              <AlignRight>Click here for more {`>>`}</AlignRight>
            </ProjectCard>

            <ProjectCard onClick={() => navigate('/building-analytics')}>
              <GatsbyImage
                image={data.buildingAnalyticsImage.childImageSharp.gatsbyImageData}
                alt="building analytics image"
              />
              <h2>Building Analytics</h2>
              <TagContainer>
                <Tag> data modeling </Tag>
                <Tag> probability & statistics </Tag>
                <Tag> sensors </Tag>
              </TagContainer>
              <OneLiner>
                The Brendan Iribe Center is outfitted with millions of actuators and sensors, each capturing details on
                temperature, occupancy, and so much more – can we use this information to optimize the functionality of
                the building?
              </OneLiner>
              <AlignRight>Click here for more {`>>`}</AlignRight>
            </ProjectCard>

            <ProjectCard onClick={() => navigate('/healthcare-language-modeling')}>
              <GatsbyImage
                image={data.healthcareImage.childImageSharp.gatsbyImageData}
                alt="healthcare language modeling image"
              />
              <h2>Healthcare Language Modeling</h2>
              <TagContainer>
                <Tag> nlp </Tag>
                <Tag> deep learning </Tag>
                <Tag> knowledge graphs </Tag>
              </TagContainer>
              <OneLiner>
                Surveyors at nursing facilities across the country are constantly reviewing notes regarding patient care
                and well-being, so let’s help them focus on just the important phrases.
              </OneLiner>
              <AlignRight>Click here for more {`>>`}</AlignRight>
            </ProjectCard>

            <ProjectCard onClick={() => navigate('/stopcovid-study')}>
              <GatsbyImage image={data.stopCovidImage.childImageSharp.gatsbyImageData} alt="breathing analysis image" />
              <h2>Stop COVID Study</h2>
              <TagContainer>
                <Tag> covid </Tag>
                <Tag> mobile apps </Tag>
                <Tag> proximity </Tag>
              </TagContainer>
              <OneLiner>
                UMD and MIND Lab investigated the effect of school-wide safety protocols on student health
              </OneLiner>
              <AlignRight>Click here for more {`>>`}</AlignRight>
            </ProjectCard>
          </ResponsiveDesktopContainer>
        </Details>
      </PageWrapper>
    </Layout>
  );
};

export default Projects;
